body {
    font-size: 18px;
    font-family: "Poor Story", "Comic Sans", cursive!important;
    text-align: center;
}

h1 {
    font-size: 5em;
    margin:0px;
}
h2 {
    font-size: 3em;
}

span {
    font-style: italic;
}

.q {
    font-size: 2em;
}

.is-funny {
    color:green;
}
.not-funny {
    color:red;
}